import React from "react";
import ForgotPasswordPage from "../react/pages/authentication/forgot-password/";
import { Providers } from "../react/common/layouts/Layout";

export default function ForgotPasswordGatsbyEntry(props) {
  return (
    <Providers>
      <ForgotPasswordPage {...props} />
    </Providers>
  );
}
