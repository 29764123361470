import React from "react";
import { Absolute, Box, Hide, Flex, Grid, Row, Col, Theme } from "pws-design-system/design-system";
import PageLayout from "../../../common/layouts/PageLayout";
import { SubNavigationLinks } from "../../../common/landmarks/footer/Footer";
import ResponsiveRenderRange from "../../../common/components/responsive-render/ResponsiveRenderRange";
import BackgroundImage from "gatsby-background-image";
import { css } from "@emotion/react";
import { graphql, StaticQuery } from "gatsby";
import { ThemeContainer } from "../../../common/hooks/useTheme";

function AuthenticationLayoutDisplay({ children, metaTitle, metaDescription }) {
  const { theme } = ThemeContainer.useContainer();

  return (
    <PageLayout
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      includeFooter={false}
      containerProps={{
        pt: 0,
        bg: theme.colors.bg.base.primary
      }}
      headerProps={{
        position: "relative",
        bg: [theme.colors.bg.base.primary, null, "transparent"],
        logoVariant: theme.components.dashboard.logo.variant,
        includeSearch: false,
        zIndex: 10
      }}
      contentProps={{
        minHeight: [null, null, "100vh"],
        bg: theme.colors.bg.base.primary
      }}
    >
      <Flex
        width="100%"
        flexDirection="column"
        minHeight={[null, null, "100vh"]}
        mt={[null, null, "-204px"]}
        mb={[4, 0]}
        zIndex={0}
      >
        <Flex flexGrow={1} flexDirection={["column", null, "row"]}>
          <ResponsiveRenderRange min={769}>
            <StaticQuery
              query={graphql`
                query {
                  desktop: file(relativePath: { eq: "pws-auth-header.jpg" }) {
                    childImageSharp {
                      fluid(quality: 80, maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              `}
              render={data => {
                // Set ImageData.
                const imageData = data.desktop.childImageSharp.fluid;
                return (
                  <BackgroundImage
                    fluid={imageData}
                    durationFadeIn={50}
                    css={css`
                      background-color: ${Theme.colors.brand.gray["700"]};
                      display: flex;
                      width: 40%;
                      background-position: right top;
                    `}
                  />
                );
              }}
            />
          </ResponsiveRenderRange>
          <Flex
            flexGrow={1}
            alignItems="center"
            justifyContent="flex-start"
            py={[0, null, "200px"]}
          >
            <Box width={["100%", null, "90%", null, "700px"]} pl={[0, null, 4, null, 6]}>
              <Grid position="relative" pt={[null, null, 4]} pb={[4, null, 0]}>
                <Row>
                  <Col>
                    {children}
                    <Hide mobile>
                      <Absolute bottom="-100px" left="0" fontSize="xs" px={[0, null, 4]}>
                        <SubNavigationLinks />
                      </Absolute>
                    </Hide>
                  </Col>
                </Row>
              </Grid>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </PageLayout>
  );
}

export default function AuthenticationLayout(props) {
  return <AuthenticationLayoutDisplay {...props}>{props.children}</AuthenticationLayoutDisplay>;
}
