import React, { useState, useEffect } from "react";
import _debounce from "lodash/debounce";

const isBrowser = typeof window !== "undefined" && window;

export default function ResponsiveRenderRange({
  min = 0,
  max = 99999,
  children,
  delay = 700
}: ResponsiveRenderRangeProps): React.ReactElement {
  const [matches, setMatches] = useState(checkSize());

  function checkSize() {
    if (isBrowser) {
      return document.body.clientWidth >= min && document.body.clientWidth <= max;
    }
    return true;
  }

  function handleResize() {
    setMatches(checkSize());
  }

  const debouncedHandleResize = _debounce(handleResize, delay, {
    leading: true
  });

  useEffect(() => {
    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  if (matches) {
    return <>{children}</>;
  }
  return null;
}
