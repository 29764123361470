import React, { useState, Children } from "react";
import { FormField, Icon, Heading, Text, Link, Box } from "pws-design-system/design-system/";
import Layout from "../common/Layout";
import { Form } from "../common/";
import { Link as ReachLink, navigate } from "gatsby";
import { useMessages } from "../../../common/hooks/useMessages";
import _get from "lodash.get";
import axios from "axios";
import { formDataToJson } from "../../../../utils";
import { ThemeContainer } from "../../../common/hooks/useTheme";

function Flash({ message }: { message: string }) {
  return (
    <Box bg="green.100" mt={2} p={2} mx={-2} rounded="md">
      <Text variant="caption1" color="green.700" dangerouslySetInnerHTML={{ __html: message }} />
    </Box>
  );
}

function ForgotPasswordPageDisplay(props): React.ReactElement {
  const { theme } = ThemeContainer.useContainer();
  const [email, setEmail] = useState();
  const flash = _get(props, "location.state.flash", null);

  const messages = useMessages();

  function handleSuccess() {
    navigate("/login", {
      state: {
        flash: `An email has been sent to ${email} containing instructions for updating your password.`
      }
    });
  }

  const handleChange = (event: React.SyntheticEvent) => {
    const value = (event.target as HTMLInputElement).value;
    setEmail(value);
  };

  const handleSubmit = async (
    event,
    setErrors,
    send,
    action,
    withCredentials,
    successHandler,
    errorHandler
  ) => {
    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);
    setErrors(null);
    send("submitting");

    try {
      const response = await axios({
        url: action,
        method: "POST",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        },
        data: formDataToJson(formData)
      });
      send("success");
      handleSuccess();
      console.log(response);
    } catch (error) {
      // const code = _get(error, "response.data.error.code");
      // const errorMessage = errorMap[code];
      // if (errorMessage) {
      //   setErrors(errorMessage.message());
      // } else {
      //   setErrors(errorMap.unknown.message());
      // }
      send("errored");
      // const code = _get(error, "response.data.error.code");
      // const errorMessage = errorMap[code];
      // if (errorMessage) {
      //   setErrors(errorMessage.message());
      // } else {
      //   setErrors(errorMap.unknown.message());
      // }
      // send("errored");
    }
  };

  return (
    <Layout
      metaTitle={_get(messages, "forgot_password_page_title", "PWSWeather")}
      metaDescription={_get(messages, "forgot_password_meta_description", "PWSWeather")}
    >
      <Form
        HeadingSlot={
          <>
            <Heading variant="headline">Reset Password</Heading>
            <Text variant="caption1">
              <Link as={ReachLink} to="/login" fontWeight="normal">
                Return to log in.
              </Link>
            </Text>
          </>
        }
        action={`${process.env.GATSBY_PWS_API_URL}${process.env.GATSBY_FORGOT_PASSWORD_URL}`}
        FlashSlot={flash && <Flash message={props.location.state.flash} />}
        successHandler={handleSuccess}
        submitHandler={handleSubmit}
      >
        <FormField
          id="email"
          name="email"
          label="Email"
          field={{
            type: "email",
            variant: "flushed",
            rightElement: <Icon name="user" color="gray.300" />,
            background: theme.colors.bg.base.primary
          }}
          onChange={handleChange}
          required
        />
      </Form>
      <Box mt={4} ml={1}>
        Can't remember the email you registered with? Please{" "}
        <Link as={ReachLink} to="/support" fontWeight="bold">
          contact support.
        </Link>
      </Box>
    </Layout>
  );
}

export default function LoginPage(props) {
  return <ForgotPasswordPageDisplay {...props}>{props.children}</ForgotPasswordPageDisplay>;
}
